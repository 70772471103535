import { Grid } from '@mui/material';
import {
  dateFormatLongDate,
  dateFormatShortTime,
  parseHMtoSeconds,
  parseSecondsToHM,
  roundHourWithThreshold,
} from '@nx-smartmonkey/shared/helpers';
import { HSpacer, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import { useState } from 'react';
import { Date } from '../../assets/svg/Date';
import { Status } from '../../assets/svg/Status';
import { StatusHistory } from '../../assets/svg/StatusHistory';
import { StopsLeft } from '../../assets/svg/StopsLeft';
import { Time } from '../../assets/svg/Time';
import { CustomersStop } from '../../domain/customersStop/CustomersStop';
import { StatusHistoryTypes, customersStopStatus } from '../../domain/projectCustomers/StatusHistory';
import { TimeOptions } from '../../domain/projectCustomers/TimeOptions';
import { ProjectStyle } from '../../domain/projectStyle/ProjectStyle';
import { useRetrieveProject } from '../../hooks/project/useRetrieveProject';
import { Text } from '../../react-components/Text';
import { VehicleAvatar } from './VehicleAvatar';

const StatusTitle = ({
  stopStatus,
  textColor,
  stop,
}: {
  stopStatus: StatusHistoryTypes;
  textColor: string;
  stop: CustomersStop;
}) => {
  const statusText = stop.getStatusText(stopStatus);
  if ((statusText ?? ``) !== ``) {
    return (
      <Text variant="body-large" style={{ color: textColor }}>
        {statusText}
      </Text>
    );
  }

  return <Text variant="body-large" style={{ color: textColor }} intlId={`customers.status.${stopStatus}`} />;
};

const getColor = (stop: CustomersStop, projectStyle?: ProjectStyle): string => {
  const stopStatus = mapStatus(stop);

  if (stopStatus === `completed`) {
    return RoutalPalette.markerStopColors.completed.medium;
  }

  if (stopStatus === `canceled`) {
    return RoutalPalette.markerStopColors.canceled.medium;
  }

  return projectStyle?.primary_color?.value || RoutalPalette.primary40;
};

const StopCreatedPill = ({ stop, projectStyle }: { stop: CustomersStop; projectStyle?: ProjectStyle }) => {
  const stopStatus = mapStatus(stop);
  if (stopStatus !== `created`) {
    return null;
  }

  const stopStatusColor = getColor(stop, projectStyle);

  return (
    <Grid item container direction="column" style={{ margin: `8px` }}>
      <Grid item>
        <StatusTitle stopStatus={stopStatus} textColor={stopStatusColor} stop={stop} />
      </Grid>
    </Grid>
  );
};

const StopScheduledPill = ({ stop, projectStyle }: { stop: CustomersStop; projectStyle?: ProjectStyle }) => {
  const stopStatus = mapStatus(stop);
  if (stopStatus !== `scheduled`) {
    return null;
  }

  const stopStatusColor = getColor(stop, projectStyle);

  return (
    <Grid item container direction="column" style={{ margin: `8px` }}>
      <Grid item>
        <StatusTitle stopStatus={stopStatus} textColor={stopStatusColor} stop={stop} />
      </Grid>
      {stop.isFieldEnabled(`planned_arrival_time`) && stop.planned_arrival_time !== undefined ? (
        <Grid item container direction="column">
          <VSpacer small />

          <Grid item container alignItems="center" style={{ width: `unset` }}>
            <Date color={stopStatusColor} />

            <HSpacer small />

            <Text style={{ marginTop: `3px`, fontSize: `11px` }}>{dateFormatLongDate(stop.execution_date)}</Text>

            <HSpacer medium />
          </Grid>

          <VSpacer small />

          <Grid item container alignItems="center" style={{ width: `unset` }}>
            <Time color={stopStatusColor} />

            <HSpacer small />

            <Text style={{ marginTop: `3px`, fontSize: `11px` }}>
              {getTimeRange(stop.planned_arrival_time, stop.planned_arrival_time_options)}
            </Text>

            <HSpacer medium />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const getTimeRange = (time: number, timeOptions?: TimeOptions) => {
  const roundedTimeSeconds = roundHourWithThreshold(time, timeOptions?.rounding_minutes ?? 0);

  if (timeOptions?.rounding_minutes === undefined) {
    return <span>{parseSecondsToHM(roundedTimeSeconds)}</span>;
  }

  const startSeconds = roundedTimeSeconds + (timeOptions?.left_bound ?? 0) * 60;
  const endSeconds = roundedTimeSeconds + (timeOptions?.right_bound ?? 0) * 60;

  return <span>{`${parseSecondsToHM(startSeconds)}-${parseSecondsToHM(endSeconds)}`}</span>;
};

const StopOnRoutePill = ({ stop, projectStyle }: { stop: CustomersStop; projectStyle?: ProjectStyle }) => {
  const stopStatus = mapStatus(stop);
  if (stopStatus !== `on_route`) {
    return null;
  }

  const stopStatusColor = getColor(stop, projectStyle);

  const estimatedArrivalTimeSeconds = stop.estimated_arrival_time
    ? parseHMtoSeconds(dateFormatShortTime(stop.estimated_arrival_time))
    : undefined;

  return (
    <Grid item container direction="column" style={{ margin: `8px` }}>
      <Grid item>
        <StatusTitle stopStatus={stopStatus} textColor={stopStatusColor} stop={stop} />
      </Grid>

      <Grid item container direction="column">
        <VSpacer small />

        {stop.isFieldEnabled(`estimated_arrival_time`) && estimatedArrivalTimeSeconds !== undefined ? (
          <Grid item container alignItems="center" style={{ width: `unset` }}>
            <Time color={stopStatusColor} />

            <HSpacer small />

            <Text style={{ marginTop: `3px`, fontSize: `11px` }}>
              {getTimeRange(estimatedArrivalTimeSeconds, stop.estimated_arrival_time_options)}
            </Text>

            <VSpacer small />
          </Grid>
        ) : null}

        {stop.isFieldEnabled(`stops_left`) ? (
          <Grid item container alignItems="center" style={{ width: `unset` }}>
            <StopsLeft color={stopStatusColor} />

            <HSpacer small />

            <Text
              intlId="customers.stops_left_num"
              intlValues={{ num: stop.stops_left ?? 0 }}
              style={{ marginTop: `3px`, fontSize: `11px` }}
            />

            <VSpacer small />
          </Grid>
        ) : null}

        {stop.isFieldEnabled(`driver_info`) ? (
          <>
            <VSpacer small />

            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <VehicleAvatar
                  color={projectStyle?.primary_color?.value ?? RoutalPalette.primary40}
                  vehicleAvatar={stop.driver_info?.avatar}
                />
              </Grid>

              <HSpacer small />

              <Grid item>
                <Text isBold>{stop.driver_info?.label}</Text>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

const StopReportPill = ({ stop, projectStyle }: { stop: CustomersStop; projectStyle?: ProjectStyle }) => {
  const stopStatus = mapStatus(stop);
  if (stopStatus !== `completed` && stopStatus !== `canceled`) {
    return null;
  }

  const stopStatusColor = getColor(stop, projectStyle);

  return (
    <Grid item container direction="column" style={{ margin: `8px` }}>
      <Grid item>
        <StatusTitle stopStatus={stopStatus} textColor={stopStatusColor} stop={stop} />
      </Grid>
      <Grid item container direction="column">
        {stop.isFieldEnabled(`report_date`) ? (
          <Grid item container alignItems="center" style={{ width: `unset` }}>
            <Date color={stopStatusColor} />

            <HSpacer small />

            <Text style={{ marginTop: `3px`, fontSize: `11px` }}>{dateFormatLongDate(stop.report_date)}</Text>

            <HSpacer medium />
          </Grid>
        ) : null}

        {stop.isFieldEnabled(`report_time`) ? (
          <Grid item container alignItems="center" style={{ width: `unset` }}>
            <Time color={stopStatusColor} />

            <HSpacer small />

            <Text style={{ marginTop: `3px`, fontSize: `11px` }}>{dateFormatShortTime(stop.report_time)}</Text>

            <HSpacer medium />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

const mapStatus = (stop: CustomersStop): StatusHistoryTypes => {
  if (stop.status === `pending`) {
    if (!stop.route_id) {
      return `created`;
    }
    if (stop.route_status === `not_started`) {
      return `scheduled`;
    }
    if (stop.route_status === `in_transit`) {
      return `on_route`;
    }
  }

  // STOP INCOMPLETE STATUS IS CONVERTED TO COMPLETED STATUS.
  return stop.status === `canceled` ? `canceled` : `completed`;
};

const getAvailableStatus = (stopStatus: StatusHistoryTypes) => {
  let status = customersStopStatus.slice(0, customersStopStatus.indexOf(stopStatus));
  if (stopStatus === `completed`) {
    status = status.filter((s) => s !== `canceled`);
  }
  if (stopStatus === `canceled`) {
    status = status.filter((s) => s !== `completed`);
  }
  return status.reverse();
};

interface CustomersCurrentStatusPillProps {
  stop: CustomersStop;
}

export const CustomersCurrentStatusPill = ({ stop }: CustomersCurrentStatusPillProps) => {
  const { data: project } = useRetrieveProject();
  const [expanded, setExpanded] = useState(false);
  const stopStatus = mapStatus(stop);
  const stopStatusColor = getColor(stop, project?.style);

  // Check if the component has to be shown due to having any field active
  // on the current status pill when status_history is not active
  if (!stop.isFieldEnabled(`status_history`)) {
    if (stopStatus === `created`) return null;

    // Check stopScheduled
    if (
      stopStatus === `scheduled` &&
      (!stop.isFieldEnabled(`planned_arrival_time`) || stop.planned_arrival_time === undefined)
    ) {
      return null;
    }

    // Check stopOnRoute
    if (
      stopStatus === `on_route` &&
      (!stop.isFieldEnabled(`estimated_arrival_time`) || stop.estimated_arrival_time === undefined) &&
      !stop.isFieldEnabled(`stops_left`) &&
      !stop.isFieldEnabled(`driver_info`)
    ) {
      return null;
    }

    // Check stopReport
    if (
      (stopStatus === `completed` || stopStatus === `canceled`) &&
      !stop.isFieldEnabled(`report_date`) &&
      !stop.isFieldEnabled(`report_time`)
    ) {
      return null;
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        style={{
          border: `1px solid ${stopStatusColor}`,
          borderRadius: `2px`,
          minHeight: `60px`,
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: stopStatusColor,
            borderRadius: `2px`,
            margin: `2px`,
            width: `30px`,
            height: `calc(100% - 4px)`,
            minHeight: `54px`,
          }}
        >
          <Status status={stopStatus} />
        </Grid>

        <StopCreatedPill stop={stop} projectStyle={project?.style} />
        <StopScheduledPill stop={stop} projectStyle={project?.style} />
        <StopOnRoutePill stop={stop} projectStyle={project?.style} />
        <StopReportPill stop={stop} projectStyle={project?.style} />
      </Grid>

      {stop.isFieldEnabled(`status_history`) && stopStatus !== `created` ? (
        <Grid container>
          {expanded ? (
            <Grid item container direction="column">
              {getAvailableStatus(stopStatus).map((status, index) => (
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  style={{
                    marginTop: `30px`,
                    border: `1px solid #CDCFD0`,
                    borderRadius: `2px`,
                    minHeight: `60px`,
                    position: `relative`,
                  }}
                >
                  <div
                    style={{
                      position: `absolute`,
                      top: `-31px`,
                      height: `31px`,
                      width: `1px`,
                      left: `15px`,
                      backgroundColor: index === 0 ? stopStatusColor : `#CDCFD0`,
                    }}
                  />
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      backgroundColor: `#CDCFD0`,
                      borderRadius: `2px`,
                      margin: `2px`,
                      width: `30px`,
                      height: `calc(100% - 4px)`,
                    }}
                  >
                    <Status status={status} />
                  </Grid>

                  <Grid item container direction="column" style={{ margin: `8px` }}>
                    <Grid item>
                      <StatusTitle stopStatus={status} textColor="#CDCFD0" stop={stop} />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : null}

          <Grid item container justifyContent="center" style={{ marginTop: `16px` }}>
            <Grid
              item
              container
              direction="row"
              style={{ cursor: `pointer`, width: `unset` }}
              onClick={() => setExpanded(!expanded)}
            >
              <StatusHistory expanded={expanded} color={stopStatusColor} />

              <HSpacer small />

              <Text variant="button" intlId="customers.last_status" style={{ color: stopStatusColor }} />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
